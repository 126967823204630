import { Injectable } from '@angular/core';
import { TargetEnum } from '@loyalty-v3/libs';
import { Partner } from '@models/partner/partner.interface';

@Injectable()
export class LoginSigninButtonsService {
  readonly getSigninCta = (partner: Partner | null) => {
    if (!partner?.partnerConfiguration?.signInCta) {
      return null;
    }

    return {
      ...partner.partnerConfiguration.signInCta,
      target: partner.partnerConfiguration.signInCta?.target ?? TargetEnum.self,
    };
  };

  readonly getSignupCta = (partner: Partner | null) => {
    if (!partner) {
      return null;
    }

    return partner.partnerConfiguration?.signUpCta
      ? {
          ...partner.partnerConfiguration.signUpCta,
          target: partner.partnerConfiguration.signUpCta?.target ?? TargetEnum.self,
        }
      : null;
  };
}
