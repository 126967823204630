import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { DesktopMainMenuComponent } from '@commons/main-menu/desktop-main-menu/desktop-main-menu.component';
import { MobileMainMenuComponent } from '@commons/main-menu/mobile-main-menu/mobile-main-menu.component';
import { MenuEntry } from '@commons/main-menu/service/entries';
import { IncentiveBannerComponent } from '@domains/incentive-banner/incentive-banner.component';
import { TargetEnum } from '@loyalty-v3/libs';
import { Store } from '@ngrx/store';
import { selectPartner } from '@stores/partner/partner.selectors';
import { isLegalGuardian } from '@stores/profile/profile.selectors';
import { MenuItem, MenuWebService, MobileMainMenuItem } from '@webservices/menu/menu.webservice';
import { isLogged } from '@wizbii-utils/angular/stores';
import { derivedAsync } from 'ngxtension/derived-async';

@Component({
  selector: 'app-main-menu',
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->

    @if (productLogo(); as productLogo) {
      <header class="tw-w-screen tw-bg-default-background-color min-[1200px]:tw-hidden">
        <a class="tw-flex tw-justify-center" [routerLink]="['/']" tabindex="-1">
          <span class="visually-hidden">Accueil</span>
          <img class="tw-h-14 tw-w-32" [src]="productLogo" height="56" width="128" alt="" placeholder imageLoader />
        </a>
        @if (isIncentiveVisible()) {
          <app-incentive-banner />
        }
      </header>
    }

    @if (menuConfig(); as menu) {
      <app-desktop-main-menu
        class="tw-fixed tw-left-1/2 tw-top-5 tw-z-10 tw-hidden tw-w-full tw-max-w-[min(calc(100%_-_calc(2_*_2.5rem)),_calc(1500px_+_calc(2_*_var(--loyal-body-padding))))] -tw-translate-x-1/2 min-[1200px]:tw-block"
        [entries]="menu.desktopEntries.mainEntries"
        [secondaryEntries]="menu.desktopEntries.secondaryEntries"
      />

      <app-mobile-main-menu
        class="tw-fixed tw-inset-x-2 tw-bottom-2 tw-z-10 tw-block min-[1200px]:tw-hidden"
        [mainEntries]="menu.mobileEntries.mainEntries"
        [secondaryEntries]="menu.mobileEntries.secondaryEntries"
      />
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DesktopMainMenuComponent, MobileMainMenuComponent, RouterLink, NgClass, IncentiveBannerComponent],
})
export class MainMenuComponent {
  readonly #store = inject(Store);
  readonly isIncentiveVisible = input<boolean | null>(null);
  readonly #menuWebService = inject(MenuWebService);

  readonly #partner = this.#store.selectSignal(selectPartner);
  readonly #isLegalGuardian = this.#store.selectSignal(isLegalGuardian);
  readonly #isUserLogged = this.#store.selectSignal(isLogged);

  readonly productLogo = computed(() => {
    const partner = this.#partner();
    if (!partner) throw Error('Missing partner');

    return partner.partnerConfiguration.assets.logos.productLogoWhite.url;
  });

  readonly #menu = derivedAsync(() => {
    if (!this.#isUserLogged()) return null;
    return this.#menuWebService.getMenu();
  });

  readonly menuConfig = computed(() => {
    const isLegalGuardian = this.#isLegalGuardian() ?? false;

    const menu = this.#menu();
    if (!menu) return null;

    const desktopEntries = {
      mainEntries: this.#transformToMainEntries(menu.desktopMenu.mainNavigation, isLegalGuardian),
      secondaryEntries: this.#transformToMainEntries(menu.desktopMenu.secondaryNavigation, isLegalGuardian),
    };
    const mobileEntries = {
      mainEntries: this.#transformMobileToMainEntries(menu.mobileMenu.mainNavigation, isLegalGuardian),
      secondaryEntries: this.#transformToMainEntries(menu.mobileMenu.secondaryNavigation, isLegalGuardian),
    };

    return { desktopEntries, mobileEntries };
  });

  readonly #transformToMainEntries = (menuItems: MenuItem[], isLegalGuardian: boolean): MenuEntry[] => {
    return menuItems.map(({ label, link, children }) => ({
      label,
      trackingLabel: isLegalGuardian ? `[menu-rl] ${label}` : `[menu] ${label}`,
      ...(link ? this.#buildLink(link) : {}),
      children: children?.map((child: MenuItem) => ({
        label: child.label,
        trackingLabel: isLegalGuardian ? `[menu-rl] ${child.label}` : `[menu] ${child.label}`,
        ...this.#buildLink(child.link),
      })),
    }));
  };

  readonly #transformMobileToMainEntries = (menuItems: MobileMainMenuItem[], isLegalGuardian: boolean): MenuEntry[] => {
    return menuItems.map(({ label, link, icon }) => ({
      label,
      trackingLabel: isLegalGuardian ? `[menu-rl] ${label}` : `[menu] ${label}`,
      icon,
      ...(link ? this.#buildLink(link) : {}),
    }));
  };

  readonly #buildLink = ({
    url: route,
    target,
  }: {
    url: string;
    target: TargetEnum;
  }): { route: string; target: TargetEnum; isInternalLink: false } | { route: string[]; isInternalLink: true } => {
    const isInternalLink = route.length > 0 && route[0] === '/';

    if (isInternalLink) {
      return {
        route: route.split('/'),
        isInternalLink,
      };
    }
    return {
      route,
      target,
      isInternalLink,
    };
  };
}
