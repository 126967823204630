import { ChangeDetectionStrategy, Component, Renderer2, effect, inject, input, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { LogoComponent } from '@commons/logo/logo.component';
import { MobileSecondaryMenuComponent } from '@commons/main-menu/mobile-main-menu/mobile-secondary-menu/mobile-secondary-menu.component';
import { MenuEntry } from '@commons/main-menu/service/entries';
import { LoyaltyIcons } from '@core/utils/icons-utils';
import { ImageLoaderDirective } from '@global/utils/image-loader';
import { trackEvent } from '@wizbii/utils/tracking';

@Component({
  selector: 'app-mobile-main-menu',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    MatIconModule,
    ImageLoaderDirective,
    LogoComponent,
    MobileSecondaryMenuComponent,
  ],
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->
    <nav class="tw-rounded-2xl tw-border tw-bg-white tw-px-4 tw-py-4" role="navigation">
      @if (mainEntries().length > 0) {
        <ul class="tw-flex tw-justify-evenly tw-gap-6" role="menu">
          @for (entry of mainEntries(); track $index) {
            <li role="none">
              @if (entry.isInternalLink) {
                <a
                  class="tw-flex tw-flex-col tw-items-center tw-gap-1.5 tw-text-xs tw-text-black tw-no-underline hover:tw-text-primary"
                  [routerLink]="entry.route"
                  (click)="trackEntry(entry.label)"
                  routerLinkActive="tw-text-primary"
                  role="menuitem"
                >
                  @if (entry.icon) {
                    <mat-icon class="tw-size-6" [svgIcon]="entry.icon" />
                  }
                  {{ entry.label }}
                </a>
              } @else {
                <a
                  class="tw-flex tw-flex-col tw-items-center tw-gap-1.5 tw-text-xs tw-text-black tw-no-underline hover:tw-text-primary"
                  [href]="entry.route"
                  [target]="entry.target"
                  (click)="trackEntry(entry.label)"
                  routerLinkActive="tw-text-primary"
                  role="menuitem"
                >
                  @if (entry.icon) {
                    <mat-icon class="tw-size-6" [svgIcon]="entry.icon" />
                  }
                  {{ entry.label }}
                </a>
              }
            </li>
          }
          <li role="none">
            <button
              class="tw-flex tw-flex-col tw-items-center tw-gap-1.5 tw-text-xs tw-text-black tw-no-underline hover:tw-text-primary"
              (click)="openMobileSecondaryMenu()"
              role="menuitem"
              aria-label="Ouvrir le sous-menu"
              type="button"
            >
              <mat-icon class="tw-size-6" [svgIcon]="burgerMenuIcon" />
              Menu
            </button>
          </li>
        </ul>
      }
    </nav>

    @if (isBurgerMenuOpen()) {
      <app-mobile-secondary-menu [(isBurgerMenuOpen)]="isBurgerMenuOpen" [secondaryEntries]="secondaryEntries()" />
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileMainMenuComponent {
  readonly mainEntries = input.required<MenuEntry[]>();
  readonly secondaryEntries = input.required<MenuEntry[]>();

  readonly isBurgerMenuOpen = signal(false);

  readonly burgerMenuIcon = LoyaltyIcons.BurgerMenu;
  readonly trackingCategory = 'menu-principal-mobile' as const;

  readonly #renderer = inject(Renderer2);

  constructor() {
    effect(() => {
      this.isBurgerMenuOpen()
        ? this.#renderer.addClass(document.body, 'block-all')
        : this.#renderer.removeClass(document.body, 'block-all');
    });
  }

  openMobileSecondaryMenu() {
    trackEvent(this.trackingCategory, 'click', 'ouvrir menu secondaire');
    this.isBurgerMenuOpen.set(true);
  }

  readonly trackEntry = (entryLabel: string) => trackEvent(this.trackingCategory, 'click', entryLabel);
}
